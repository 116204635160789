<template>
  <div class="BackstageNoticeList-wrapper">
    <list ref="list"
          :exportMethod="exportMethod"
          :exportPermission="true"
          :searchUrl="searchUrl"
          :exportUrl="getSignOverviewExportURL"
          :extraParams="extraParams"
          :searchParams.sync="searchParams"
          :headers="headers">
      <template #headerSlot>
        <v-button text="返回"
                  @click="goBack"></v-button>
      </template>
      <template #searchSlot>
        <v-datepicker label="签收时间"
                      type="rangedatetimer"
                      defaultTimeType="week"
                      :startTime.sync="searchParams.startTime"
                      :endTime.sync="searchParams.endTime"></v-datepicker>
        <v-input label="姓名"
                 v-model="searchParams.userName"></v-input>
        <v-input label="手机号"
                 v-model="searchParams.mobile"></v-input>
        <v-select2 ref="sysOrgIdSelect2"
                   :label="`所属${showOrgName}`"
                   :placeholder="`查询${showOrgName}`"
                   v-model="searchParams.orgId"
                   v-bind="sysOrgIdParams"></v-select2>
        <v-select2 ref="communitySelect2"
                   label="所属项目"
                   placeholder="查询项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams"></v-select2>
        <v-input label="苑"
                 v-model="searchParams.blockName"></v-input>
        <v-input label="幢"
                 v-model="searchParams.buildingNumber"></v-input>
        <v-input label="单元"
                 v-model="searchParams.unit"></v-input>
        <v-input label="室"
                 v-model="searchParams.room"></v-input>
        <v-select2 ref="regionSelect2"
                   label="所属分公司"
                   placeholder="查询分子公司"
                   v-model="searchParams.areaId"
                   v-bind="tenantParams"></v-select2>
        <v-select label="签收状态"
                  v-model="searchParams.signStatus"
                  :options="signStatusOps"></v-select>
      </template>
    </list>
  </div>
</template>
<script>
import { getSignOverviewListURL, getSignOverviewExportURL, getSysOrgURL } from './api'
import { signStatusOps } from './map'
import { tenantParams, communityParams } from 'common/select2Params'
export default {
  name: 'signOverviewList',
  data () {
    let ORG_NAME = this.$store.state.baseConfig.docConfig.ORG_NAME
    return {
      searchUrl: getSignOverviewListURL,
      exportMethod: 'new',
      getSignOverviewExportURL,
      signStatusOps: signStatusOps(1),
      tenantParams,
      extraParams: {},
      communityParams,
      sysOrgIdParams: {
        searchUrl: getSysOrgURL,
        request: {
          text: 'orgName',
          value: 'id'
        },
        response: {
          text: 'orgName',
          value: 'id'
        },
        method: 'get'
      },
      searchParams: {
        userName: '',
        mobile: '',
        orgId: undefined,
        communityId: undefined,
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        areaId: undefined,
        signStatus: undefined,
        startTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'sex',
          label: '性别'
        },
        {
          prop: 'mobile',
          label: '手机号'
        },
        {
          prop: 'orgName',
          label: `所属${ORG_NAME}`
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属分公司'
        },
        {
          prop: 'houseAddress',
          label: '项目住址'
        },
        {
          prop: 'signStatus',
          label: '签收状态'
        },
        {
          prop: 'updateTime',
          label: '签收时间'
        }
      ]
    }
  },

  computed: {
    showOrgName () {
      return this.$store.state.baseConfig.docConfig.ORG_NAME || ''
    }
  },
  created () {
    this.$setBreadcrumb('签收概览')
    this.extraParams.signNo = this.$route.query.signNo
    this.extraParams.receiveUser = this.$route.query.receiveUser
    this.extraParams.sendType = this.$route.query.sendType
    this.extraParams.appType = this.$route.query.appType
    this.extraParams.noticeTypeForSearch = this.$route.query.noticeTypeForSearch
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
